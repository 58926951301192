<template>
  <Modal
    title="Save lineup as preset?"
    :visible="confirmModalActive"
    class="confirm-lineup-save-modal"
    @close="onModalClose"
  >
    <div class="modal-text">
      <p>You can save selected lineup as a preset, and reuse it anytime you edit this team.</p>
      <p>Please input a name of a lineup:</p>
    </div>
    <TextInput
      class="lineup-name-input"
      :model-value="lineupName"
      @update:modelValue="onLineupNameChange"
      placeholder="Input lineup name here"
      small
    />
    <div class="modal-actions">
      <Button
        @click="onModalClose"
        variant="tertiary"
      >
        Cancel
      </Button>
      <Button
        v-if="isSelectedLineupPreset"
        @click="onUpdateExistingLineupConfirm"
        variant="secondary"
      >
        Update existing preset
      </Button>
      <Button
        @click="onSaveNewLineupConfirm"
        :disabled="!lineupName"
        variant="primary"
      >
        Save new
      </Button>
    </div>
  </Modal>
</template>

<script>
import { ref } from 'vue';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import TextInput from '@/components/common/TextInput';

export default {
  emits: ['onModalClose', 'onSaveLineupConfirm', 'onUpdateLineupConfirm'],
  props: {
    confirmModalActive: {
      type: Boolean,
      default: false,
    },
    isSelectedLineupPreset: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Button,
    Modal,
    TextInput,
  },
  setup(_, { emit }) {
    const lineupName = ref('');
    const clearLineupName = () => {
      lineupName.value = '';
    };
    const onModalClose = () => {
      emit('onModalClose');
      clearLineupName();
    };
    const onSaveNewLineupConfirm = () => {
      emit('onSaveLineupConfirm', lineupName.value);
      clearLineupName();
    };
    const onUpdateExistingLineupConfirm = () => {
      emit('onUpdateLineupConfirm');
      clearLineupName();
    };
    const onLineupNameChange = (val) => {
      lineupName.value = val;
    };
    return {
      lineupName,
      onModalClose,
      onSaveNewLineupConfirm,
      onLineupNameChange,
      onUpdateExistingLineupConfirm,
    };
  },
};
</script>

<style lang="scss">
  .confirm-lineup-save-modal {
    .modal__dialog {
      max-width: 360px !important;
      .modal__content {
        .modal-text {
          font-size: 14px;
          color: $gray800;
          p:last-child {
            margin-top: 16px;
          }
        }
        .lineup-name-input {
          margin: 8px 0 16px 0;
        }
       .modal-actions {
          padding-top: 16px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          border-top: 1px solid $gray400;
        }
      }
    }
  }
</style>
