<template>
  <Modal
    :title="`Delete ${lineupPresetName} preset?`"
    :visible="confirmModalActive"
    class="confirm-lineup-delete-modal"
    @close="onModalClose"
  >
    <div class="modal-text">
      <p>If you proceed, the preset will be permanently deleted.</p>
    </div>
    <div class="modal-actions">
      <Button
        @click="onModalClose"
        variant="tertiary"
      >
        Cancel
      </Button>
      <Button
        @click="onDeleteLineupConfirm"
        variant="danger"
      >
        Delete preset
      </Button>
    </div>
  </Modal>
</template>

<script>
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';

export default {
  emits: ['onModalClose', 'onDeleteLineupConfirm'],
  props: {
    confirmModalActive: {
      type: Boolean,
      default: false,
    },
    lineupPresetName: {
      type: String,
      default: '',
    },
  },
  components: {
    Button,
    Modal,
  },
  setup(_, { emit }) {
    const onModalClose = () => {
      emit('onModalClose');
    };
    const onDeleteLineupConfirm = () => {
      emit('onDeleteLineupConfirm');
    };
    return {
      onModalClose,
      onDeleteLineupConfirm,
    };
  },
};
</script>

<style lang="scss">
  .confirm-lineup-delete-modal {
    .modal__dialog {
      max-width: 360px !important;
      .modal__content {
        .modal-text {
          font-size: 14px;
          color: $gray800;
          padding-bottom: 16px;
        }
       .modal-actions {
          padding-top: 16px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          border-top: 1px solid $gray400;
        }
      }
    }
  }
</style>
